
export default {
  Canvas: {
    CanvasWidth: 500,
    CanvasHeight: 400,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: 'Transparent',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 500,
    CanvasChartHeight: 400,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {AREA:'Area 01',  EREC: 'BK342' , PE2: 'BK43' , PE1: 'B276' , PLAN_START: 1154, PLAN_FINISH: 35, ACT_START: 1232, ACT_FINISH: 40, DELTA: -24, PROG: 84.7,},
    {AREA:'Area 02',  EREC: 'BK345' , PE2: 'BK43' , PE1: 'B654' , PLAN_START: 2214, PLAN_FINISH: 45, ACT_START: 1524, ACT_FINISH: 254, DELTA: -2,  PROG: 24.5,},
    {AREA:'Area 03',  EREC: 'BK346' , PE2: 'BK43' , PE1: 'B341' , PLAN_START: 3342, PLAN_FINISH: 45, ACT_START: 1324, ACT_FINISH: 264, DELTA: 10,  PROG: 57.4,},
    {AREA:'Area 04',  EREC: 'BK349' , PE2: 'BK43' , PE1: ''     , PLAN_START: 12234, PLAN_FINISH: 44, ACT_START: 2322, ACT_FINISH: 242, DELTA: 7,   PROG: 45.3,},
    {AREA:'Area 05',  EREC: 'BK442' , PE2: 'BK23' , PE1: ''     , PLAN_START: 2100, PLAN_FINISH: 32, ACT_START: 1223, ACT_FINISH: 265, DELTA: 9,   PROG: 54.0,},
    {AREA:'Area 06',  EREC: 'BK372' , PE2: ''     , PE1: 'AP234', PLAN_START: 1287, PLAN_FINISH: 2237, ACT_START: 3156, ACT_FINISH: 2100, DELTA: 0,   PROG: 100.0,},
    {AREA:'Area 07',  EREC: 'BK362' , PE2: ''     , PE1: 'AP765', PLAN_START: 1536, PLAN_FINISH: 2287, ACT_START: 4154, ACT_FINISH: 288, DELTA: -2,  PROG: 87.7,},
    {AREA:'Area 08',  EREC: 'BK371' , PE2: ''     , PE1: 'AP987', PLAN_START: 1847, PLAN_FINISH: 1269, ACT_START: 2178, ACT_FINISH: 241, DELTA: -14, PROG: 74.4,},
    {AREA:'Area 09',  EREC: 'BK341' , PE2: ''     , PE1: ''     , PLAN_START: 1151, PLAN_FINISH: 54, ACT_START: 1816, ACT_FINISH: 246, DELTA: -9,  PROG: 71.2,},
    {AREA:'Area 10',  EREC: 'BK312' , PE2: ''     , PE1: 'AE23' , PLAN_START: 1364, PLAN_FINISH: 1587, ACT_START: 2110, ACT_FINISH: 156, DELTA: 14,  PROG: 25.1,},
    {AREA:'Area 11',  EREC: 'BK322' , PE2: ''     , PE1: 'AE34' , PLAN_START: 1079, PLAN_FINISH: 45, ACT_START: 1187, ACT_FINISH: 128, DELTA: 13,  PROG: 39.9,},
    {AREA:'Area 12',  EREC: 'BK332' , PE2: ''     , PE1: 'AE33' , PLAN_START: 198, PLAN_FINISH: 61, ACT_START: 1165, ACT_FINISH: 39, DELTA: 'n/a',  PROG: 49.6,},
  ],

  Queries: {

    SQL1: [ // --> Header
      {
        X: 10,
        Y: 10,

        HEADER_HEIGHT: 30,
        HEADER_BACKGROUND: '#E0E0DF',
        HEADER_OPACITY: 0.3,
        HEADER_LINE_COLOR: '#333',
        HEADER_LINE_SIZE: .5,
        HEADER_FONT_COLOR: '#000',
        HEADER_FONT_WEIGHT: 'nomal',
        HEADER_FONT_SIZE: 12,

        DATA_HEIGHT: 20,
        DATA_LINE_COLOR: '#bcbcbc',
        DATA_LINE_SIZE: 0.2,
        DATA_FONT_COLOR: 'gray',

        DATA_FONT_WEIGHT: 'nomal',
        DATA_FONT_SIZE: 11,
        DATA_HOVOR: '#bcbcbc'
      },
    ],

    SQL2: [ // --> Columns
      {CODE: 'AREA'       , NAME: 'AREA'         , TYPE: 'BUTTON'   , COL_LINK: 'KEY1'  , JSON: 'JSON_1', FILTER: '' ,   WIDTH: 60  , ALIGN: 'middle'  , PADDING: 0  , TCOLOR: 'gray',     BCOLOR: ''       },
      {CODE: 'EREC'       , NAME: 'EREC'         , TYPE: ''         , COL_LINK: 'KEY2'  , JSON: '',       FILTER: '' ,   WIDTH: 50  , ALIGN: 'middle'  , PADDING: 0  , TCOLOR: 'gray',     BCOLOR: ''       },
      {CODE: 'PE2'        , NAME: 'PE2'          , TYPE: ''         , COL_LINK: ''      , JSON: '',       FILTER: '' ,   WIDTH: 50  , ALIGN: 'middle'  , PADDING: 0  , TCOLOR: 'gray',     BCOLOR: ''       },
      {CODE: 'PE1'        , NAME: 'PE1'          , TYPE: ''         , COL_LINK: ''      , JSON: '',       FILTER: '' ,   WIDTH: 50  , ALIGN: 'middle'  , PADDING: 0  , TCOLOR: 'gray',     BCOLOR: ''       },
      {CODE: 'PLAN_START' , NAME: 'Start/Plan'   , TYPE: 'LINK$'    , COL_LINK: ''      , JSON: 'JSON_1', FILTER: 'F1' , WIDTH: 50  , ALIGN: 'end'     , PADDING: 10 , TCOLOR: 'gray',     BCOLOR: ''       },
      {CODE: 'PLAN_FINISH', NAME: 'Finish'       , TYPE: 'LINK$'    , COL_LINK: ''      , JSON: 'JSON_1', FILTER: 'F2' , WIDTH: 50  , ALIGN: 'end'     , PADDING: 10 , TCOLOR: '#F35E90',  BCOLOR: ''       },
      {CODE: 'ACT_START'  , NAME: 'Start/Actual' , TYPE: 'LINK$'    , COL_LINK: ''      , JSON: 'JSON_1', FILTER: 'F3' , WIDTH: 50  , ALIGN: 'end'     , PADDING: 10 , TCOLOR: 'gray',     BCOLOR: ''       },
      {CODE: 'ACT_FINISH' , NAME: 'Finish'       , TYPE: 'LINK$'    , COL_LINK: ''      , JSON: 'JSON_1', FILTER: 'F4' , WIDTH: 50  , ALIGN: 'end'     , PADDING: 10 , TCOLOR: '#F35E90',  BCOLOR: ''       },
      {CODE: 'DELTA'      , NAME: 'Delta'        , TYPE: 'DELTA'    , COL_LINK: ''      , JSON: '',       FILTER: '' ,   WIDTH: 60  , ALIGN: 'middle'  , PADDING: 10 , TCOLOR: '#83D2F5',  BCOLOR: ''       },
      {CODE: 'PROG'       , NAME: 'Progress'     , TYPE: 'PROG'     , COL_LINK: ''      , JSON: '',       FILTER: '' ,   WIDTH: 150 , ALIGN: ''        , PADDING: 10 , TCOLOR: 'gray',     BCOLOR: 'pink'   },
    ],

    SQL3: [ // --> Filter
      {
        F1 : " 1=1 ",
        F2 : " 2=2 ",
        F3 : " 3=3 ",
        F4 : " 4=4 ",
      },
    ],

  },

}