
export default {
  Canvas: {
    CanvasWidth: 700,
    CanvasHeight: 700,
    CanvasBorderWeight: .5,
    CanvasBorderColor: '#E0E0DF',
    CanvasFillColor: '#fff',
    CanvasOpacity: 1,

    CanvasChartX: 0,
    CanvasChartY: 0,
    CanvasChartWidth: 300,
    CanvasChartHeight: 300,
    CanvasChartBorderWeight: 0,
    CanvasChartBorderColor: '#757575',
    CanvasChartFillColor: '#000',
    CanvasChartOpacity: 1,
    CanvasChartSpace: 0,

    CanvasFontFamily: 'roboto',
  },

  Text: {
    Text: [],
    Filter: [],
    Sql: []
  },

  DataItems: [
    {LEV1: 'HULL', LEV2: 'EN250'},
  ],

  Queries: {

    SQL1: [
      {
        PATH: '/Visualization/BlockDivision/BlockImage',
        EXTENSION: 'jpg',
        X: 0,
        Y: 0,
        SIZE: 300,
        BASE: 'width',
      }
    ],

  },

}