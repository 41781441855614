import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    draw_Chart_Controller() {

      this.Area_Chart = this.svg
      .append('g')
      .attr('id', `Area_Chart`)
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto

      // Set bandwidth of the Bar Chart
      let gap = 6
      let count = this.Discipline.filter(f=> f.checked == true && f.ITEM != 'CP').length
      let width = this.barWidth - (gap * count) - 1
      let x = -(this.barWidth - 2)
      width = width / count
      
      this.Discipline.filter(f=> f.checked == true).forEach((d,i)=> {
        if(d.ITEM == 'CP') return
        let data = this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC)

        this.draw_BarChart({
          data:     data,
          item:     d.ITEM,
          disc:     d.DISC,
          scale:    this.scale.Secondary,
          x:        x,
          width:    width,
          color:    '',
          opacity:  .5
        })
        x += width + 1

        this.draw_LineChart({
          data:     this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), 
          item:     d.ITEM,
          disc:     d.DISC,
          scale:    this.scale.Primary, 
          color:    '#757575',
          opacity:  .8,
          x:        this.barWidth / 2,
          col:      'REMAIN_CUM',
          no:       1,
        })

        this.draw_LineChart({
          data:     this.rawData.filter(f=> f.ITEM == d.ITEM && f.DISC == d.DISC), 
          item:     d.ITEM,
          disc:     d.DISC,
          scale:    this.scale.Primary, 
          color:    '#93b8ff',
          opacity:  .8,
          x:        this.barWidth / 2,
          col:      'CONT_REMAIN',
          no:       2,
        })
      })
    },



    draw_BarChart(cht) {
      let this_ = this
      // CREATED
      let BarChart = this.Area_Chart
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('opacity', cht.opacity)

      BarChart
      .selectAll(`rect_`)
      .data(cht.data)
      .enter()
      .append('rect')
      .attr('id', (d,i)=> `BAR_CREATED_${i}`)
      .attr('x', d => {
        return this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()) + cht.x
      })
      .attr('y', d => cht.scale(d.CREATED_WK))
      .attr('height', d => this.canvas.chart.height/2 - cht.scale(d.CREATED_WK))
      .attr('width', cht.width)
      .attr('fill', '#F7BACF')
      .attr('stroke', '#EC407A').attr('stroke-width', 1).attr('stroke-opacity', .75)

      BarChart
      .selectAll(`text_`)
      .data(cht.data)
      .enter()
      .append('text')
      .attr('id', (d,i)=> `VALUE_CREATED_${i}`)
      .attr('x', d => {
        return this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()) + cht.x + (cht.width/2)
      })
      .attr('y', d => cht.scale(d.CREATED_WK) - 2)
      .attr('font-size', 8.5).attr('text-anchor', 'middle')
      .attr('fill', '#EC407A').attr('opacity', d=> d.CREATED_WK == 0 ? 0.3 : 1)
      .text(d => d.CREATED_WK)
      .each(function(d) {
        d3.select(this).call(this_.call_History, {cDate: d.CDATE, status: 'CREATE'})
      })


      // CLOSED
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('opacity', cht.opacity)

      BarChart
      .selectAll(`rect_`)
      .data(cht.data)
      .enter()
      .append('rect')
      .attr('id', (d,i)=> `BAR_CLOSED_${i}`)
      .attr('x', d => {
        return this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()) + cht.x
      })
      .attr('y', this.canvas.chart.height/2)
      .attr('height', d => this.canvas.chart.height/2 - cht.scale(d.CLOSED_WK))
      .attr('width', cht.width)
      .attr('fill', '#8BC248')
      .attr('stroke', '#4CAE4E').attr('stroke-width', 1).attr('stroke-opacity', .75)
      
      BarChart
      .selectAll(`text_`)
      .data(cht.data)
      .enter()
      .append('text')
      .attr('id', (d,i)=> `VALUE_CLOSED_${i}`)
      .attr('x', d => {
        return this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate()) + cht.x + (cht.width/2)
      })
      .attr('y', d => this.canvas.chart.height/2 - cht.scale(d.CLOSED_WK) + this.canvas.chart.height/2 + 10)
      .attr('font-size', 8.5).attr('text-anchor', 'middle')
      .attr('fill', '#4CAE4E').attr('opacity', d=> d.CLOSED_WK == 0 ? 0.3 : 1)
      .text(d => d.CLOSED_WK)
      .each(function(d) {
        d3.select(this).call(this_.call_History, {cDate: d.CDATE, status: 'CLOSE'})
      })
    },



    draw_LineChart(cht) {

      let lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x)
      .y(d => cht.scale(d[cht.col]))

      let final = {date: 0, value: 0}
      let lineChart = this.Area_Chart
      .append('g')
      .attr('transform', `translate(0,0)`)
      .attr('opacity', cht.opacity)

      lineChart
      .append('path')
      .attr('id', (d,i)=> `LINE_CHART_${cht.no}`)
      .attr('d', lineFunc(cht.data))
      .style('stroke', cht.color).style('stroke-width', 1)
      .style('fill', 'none')

      lineChart
      .selectAll(`circle_`)
      .data(cht.data)
      .enter()
      .append('circle')
      .attr('id', (d,i)=> `NODE_${cht.no}_${i}`)
      .attr('cx', d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x)
      .attr('cy', d=>{
        final.date = d.CDATE
        final.value = d[cht.col]
        return cht.scale(d[cht.col])
      })
      .attr('r', 2)
      .style('stroke', cht.color).style('stroke-width', 1)
      .style('fill', '#fff')

      // Text Remain
      let last = cht.data.at(-1)
      lineChart
      .append('text')
      .attr('id', `LAST_VALUE_${cht.no}`)
      .attr('x', this.timeline.scale(moment(moment(last.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-cht.x + 6)
      .attr('y', cht.scale(last[cht.col]) + 3.5)
      .attr('font-weight', 410)
      .attr('font-size', 11).attr('fill', '#000').attr('text-anchor', 'start')
      .text(last[cht.col])

    },

    last_Turnover() {
      
      let Turnover = this.Area_Canvas
      .append('g')
      .attr('transform', `translate(${this.canvas.padding.left+.5}, ${this.canvas.padding.top+.5})`)
      .attr('font-family', 'roboto') // rage / roboto

      let getDate = ''
      if (this.current_Unit == 'OV') {
        getDate = d3.max(this.Turnover, c => c.PLAN_MAX)
      } else {
        getDate = this.Turnover.find(f=> f.PLAN_MAX == this.current_Unit)
      }
      let last_Turnover = moment(moment(getDate).format('YYYY-MM-DD 23:59:59')).toDate()

      Turnover
      .append('line')
      .attr('x1', this.timeline.scale(last_Turnover)).attr('y1', 0)
      .attr('x2', this.timeline.scale(last_Turnover)).attr('y2', this.canvas.chart.height)
      .attr('stroke', '#757575').attr('stroke-width', .3)

      Turnover
      .append('line')
      .attr('x1', this.timeline.scale(last_Turnover)).attr('y1', 0)
      .attr('x2', this.timeline.scale(last_Turnover)-20).attr('y2', -20)
      .attr('stroke', '#757575').attr('stroke-width', .2)

      Turnover
      .append('rect')
      .attr('x', this.timeline.scale(last_Turnover)-120).attr('y', -31)
      .attr('width', 100).attr('height', 13).attr('fill', '#fff')
      .attr('stroke', '#757575').attr('stroke-width', .2)

      Turnover
      .append('text')
      .attr('x', this.timeline.scale(last_Turnover)-24).attr('y', -22)
      .attr('font-size', 9).attr('fill', '#757575').attr('text-anchor', 'end')
      .text('Last T/O Forecast Date')
    }

  }
}