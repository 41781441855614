import * as d3 from 'd3'

export default {
  methods: {

    draw_Data() {
      let self_ = this
      let dataArea = this.DataTable
      
      dataArea
        .append('g')
        .attr('id',`id_${this.localId}`)
        .attr('transform', `translate(0, ${this.Header.height + (this.Data.height/2)})`)

      this.DataItems.forEach((d,i) => {
        
        if (i < this.DataItems.length -1) {
          if (d[Object.keys(d)[0]] == this.DataItems[i+1][Object.keys(d)[0]]) {
            //
          }
          else {
            dataArea
            .select(`#id_${this.localId}`)
            .append('line')
            .attr('x1', 0)
            .attr('y1', i * this.Data.height + (this.Data.height/2))
            .attr('x2', this.TableWidth)
            .attr('y2', i * this.Data.height + (this.Data.height/2))
            .attr('stroke', this.Data.line.color)
            .attr('stroke-width', this.Data.line.size)
          }
        }
        if (i == this.DataItems.length -1) {
          dataArea
          .select(`#id_${this.localId}`)
          .append('line')
          .attr('x1', 0)
          .attr('y1', i * this.Data.height + (this.Data.height/2))
          .attr('x2', this.TableWidth)
          .attr('y2', i * this.Data.height + (this.Data.height/2))
          .attr('stroke', this.Data.line.color)
          .attr('stroke-width', this.Data.line.size)
        }
      
        // draW DATA
        this.Columns.forEach((col,j) => {

          // Button
          if (col.TYPE == 'BUTTON') {
            dataArea
            .select(`#id_${this.localId}`)
            .append('rect')
            .attr('id', `Btn_${col.CODE}_${i}`)
            .attr('x', col.col - col.WIDTH + 2.5)
            .attr('y', (i * this.Data.height) - this.Data.font.size + 4)
            .attr('rx', 2).attr('ry', 2)
            .attr('width', col.WIDTH - 2)
            .attr('height', this.Data.height - 5)
            .attr('fill', '#E0E0DF')
            .attr('stroke', '#757575').attr('stroke-width', .3)
            .attr('opacity', .5)
          }
          
          // Progress 
          if (col.TYPE == 'PROG') {
            let progWidth = (col.WIDTH - 35) * (d[col.CODE]/100)
            dataArea
            .select(`#id_${this.localId}`)
            .append('rect')
            .attr('x', col.x)
            .attr('y', (i * this.Data.height) - (this.Data.font.size/2))
            .attr('width', progWidth)
            .attr('height', this.Data.font.size)
            .attr('fill', col.BCOLOR)
            .attr('opacity', 0.5)
          }

          if (i > 0 && j == 0) {
            if (d[Object.keys(d)[0]] == this.DataItems[i-1][Object.keys(d)[0]]) return
          }
          dataArea
          .select(`#id_${this.localId}`)
          .append('text')
          .attr('x', col.TYPE == 'PROG' ? col.x + (col.WIDTH-35)*(d[col.CODE]/100) + 2 : col.x)
          .attr('y', i*this.Data.height + 1)
          .attr('font-weight',this.Data.font.weight)
          .attr('font-size', this.Data.font.size)
          .attr('fill', () => {
            if (col.TYPE == 'DELTA') {
              if(isNaN(d[col.CODE])) return '#E0E0DF'
              if (d[col.CODE] > 0) return '#44A9DF'
              if (d[col.CODE] == 0) return '#757575'
              if (d[col.CODE] < 0) return '#F35E90'
            } else {
              return col.TCOLOR
            }
          })
          .attr('text-anchor', col.TYPE == 'PROG' ? 'start' : col.ALIGN)
          .attr('alignment-baseline', 'middle')
          .text( ()=> {
            let value = d[col.CODE]
            if (col.FORMAT == '$') value = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            if (col.TYPE == 'PROG') value = `${value}%`
            return value
          })
          // link action
          .style('cursor', col.TYPE == 'LINK' || col.TYPE == 'BUTTON' ? 'pointer' : 'default')
          .on('mouseover', function() {
            d3.select(this).attr('font-weight', col.TYPE == 'LINK' ? 500 : 'nomal')
            if (col.TYPE == 'BUTTON') {
              d3.select(`#Btn_${col.CODE}_${i}`).attr('opacity', .3)
              d3.select(this).attr('font-weight', 500)
            }
          })
          .on('mouseout', function() { 
            d3.select(this).attr('font-weight', self_.Data.font.weight)
            if (col.TYPE == 'BUTTON') {
              d3.select(`#Btn_${col.CODE}_${i}`).attr('opacity', .5)
            }
          })
          .on('click', d => {  

            col.TYPE == 'LINK' ? this.link_Data(i, col) : null
          })
          // end link action
        })
      })
    },

    drawFormat(type_, format_,  data_) {
      let value = data_
      if (format_ == '$') value = data_.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      if (type_ == 'PROG') value = `${value}%`
      return value
    },


    
  }
}