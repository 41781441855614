import * as d3 from 'd3'
import Defs from '../../../../../includes/primitives/colorDefs'
import CONST from '../../../../../includes/primitives/_constant_trion'

export default {
  data: () => ({
    HOST: CONST.HOST_NAME_RESOURCE + '/resources',

    rawData: [],
    
    route: null,

    extension: null,

    x: 0,
    y: 0,
    size: 0,
    base: '',
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {
    set_LocalValues(){
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))
      let sql1 = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))
      let dir = ''

      let arr = Object.keys(this.rawData[0])

      arr.forEach((d,i) => {
        dir += `/${this.rawData[0][d]}`
      })
      dir += `.${sql1.EXTENSION}`

      this.route = `${this.HOST}${sql1.PATH}${dir}`
      this.extension = sql1.EXTENSION
      this.x = sql1.X
      this.y = sql1.Y
      this.size = sql1.SIZE
      this.base = sql1.BASE
    },
  }
}