import * as d3 from 'd3'

export default {

  methods: {

    draw_Legends() {
      let Legends = this.Area_Chart
      .append('g')
      .attr('transform', `translate(.5, ${-40.5})`)
      .style('font-family', 'roboto').style('cursor', 'default')

      Legends
      .append('rect')
      .attr('id', `LEGEND_BOX`)
      .attr('x', 0).attr('y', 0)
      .attr('width', 6)
      .attr('height', 23)
      .attr('fill', '#83D2F5')

      Legends
      .append('text')
      .attr('id', `LEGEND_TXT`)
      .attr('x', 9).attr('y', 8)
      .attr('font-size', 11)
      .attr('fill', '#333')
      .text(`A Punch`)

      Legends
      .append('text')
      .attr('id', `LEGEND_DISC`)
      .attr('x', 9).attr('y', 21)
      .attr('font-size', 11).attr('fill', '#757575')
      .text(`Overall`)

      Legends
      .append('line')
      .attr('x1', 100).attr('y1', 10)
      .attr('x2', 110).attr('y2', 10)
      .attr('stroke', '#757575')

      Legends
      .append('text')
      .attr('x', 115).attr('y', 10)
      .attr('font-size', 11)
      .attr('fill', '#333')
      .attr('alignment-baseline', 'central')
      .text(`EM`)

      Legends
      .append('line')
      .attr('x1', 150).attr('y1', 10)
      .attr('x2', 160).attr('y2', 10)
      .attr('stroke', '#93b8ff')

      Legends
      .append('text')
      .attr('x', 165).attr('y', 10)
      .attr('font-size', 11)
      .attr('fill', '#333')
      .attr('alignment-baseline', 'central')
      .text(`Contractor`)
    },


    

    

    
  }
}