import * as d3 from 'd3'

export default {

  methods: {
    /*
      gmx_btn_Toggle()
      gmx_btn_Navigation()
    */
    
    draw_Toggle_v1(selection, style_, callFunc) {
      let status = this[style_.variable]
      selection
      .append('g')
      .attr('id', `${style_.id}_group`)
      .attr('transform', `translate(${style_.x}, ${style_.y})`)
      .style('font-size', 10)
      .style('cursor', 'pointer')
      .call(callFunc, style_)
      
      d3.select(`#${style_.id}_group`)
      .append('rect')
      .attr('class', `${style_.id}_bg`)
      .attr('transform', `translate(20, 0)`)
      .attr('width', style_.width).attr('height', 18)
      .attr('fill', style_[status].bcolor)
      .attr('rx', '10')

      d3.select(`#${style_.id}_group`)
      .append('circle')
      .attr('class', `${style_.id}_slider`)
      .attr('transform', `translate(${style_[status].slide_x}, ${style_[status].slide_y})`)
      .attr("r", 6).attr('fill', style_.ccolor)

      d3.select(`#${style_.id}_group`)
      .append('text')
      .attr('class', `${style_.id}_text`)
      .attr('transform', `translate(${style_[status].text_x}, ${style_[status].text_y})`)
      .style('fill', style_[status].tcolor).style('alignment-baseline', 'middle')
      .text(style_[status].value)

      d3.select(`#${style_.id}_group`)
      .append('text')
      .attr('transform', `translate(65, 9)`)
      .style('font-size', 10)
      .style('alignment-baseline', 'middle')
      .style('fill', '#000')
      .text(style_.text)
    },

    toggle_Action_v1(status, style_) {
      d3.select(`.${style_.id}_bg`)
      .transition()
      .attr('fill', style_[status].bcolor)

      d3.select(`.${style_.id}_slider`)
      .transition()
      .attr('transform', `translate(${style_[status].slide_x}, ${style_[status].slide_y})`)

      d3.select(`.${style_.id}_text`)
      .transition()
      .attr('transform', `translate(${style_[status].text_x}, ${style_[status].text_y})`)
      .style('fill', style_[status].tcolor)
      .text(style_[status].value)
    },



    // Toggle Button Controller ______________________________________________________________________________________________________________________________
    // gmx_btn_Toggle()

    gmx_btn_Toggle(selection, s, execFunc) {
      let status = this[s.variable]

      selection
      .append('g')
      .attr('id', `${s.id}_GROUP`)
      .attr('transform', `translate(${s.x}, ${s.y})`)
      .attr('font-size', 10)
      .attr('cursor', 'pointer')
      .on('click', () => { 
        (this[s.variable] == 'on') ? this[s.variable] = 'off' :this[s.variable] = 'on'
        
        let status = this[s.variable]
        d3.select(`#${s.id}_BG`).attr('fill', s[status].bColor)
        d3.select(`#${s.id}_SLIDER`).transition().attr('cx', (status=='on') ? s.height/2 : s.width-(s.height/2))
        d3.select(`#${s.id}_TEXT`).transition()
        .attr('x', (status=='on') ? s.width-(s.height/2) : s.height/2)
        .attr('text-anchor', (status=='on') ? 'end' : 'start')
        .attr('fill', s[status].tColor)
        .text(s[status].value)

        execFunc() // ----->
      })
      
      let group = d3.select(`#${s.id}_GROUP`)
      group
      .append('rect')
      .attr('id', `${s.id}_BG`)
      .attr('width', s.width).attr('height', s.height)
      .attr('fill', s[status].bColor).attr('rx', '10')

      group
      .append('circle')
      .attr('id', `${s.id}_SLIDER`)
      .attr('cx', s.height/2).attr('cy', s.height/2)
      .attr('r', 6).attr('fill', s.cColor)

      group
      .append('text')
      .attr('id', `${s.id}_TEXT`)
      .attr('x', s.width-(s.height/2)).attr('y', s.height/2).attr('text-anchor', 'end')
      .attr('fill', s[status].tColor).attr('alignment-baseline', 'middle')
      .text(s[status].value)

      group
      .append('text')
      .attr('x', s.width+5).attr('y', s.height/2)
      .attr('alignment-baseline', 'middle').style('fill', '#000').attr('font-size', 10)
      .text(s.title)
    },



    // Navigation Button Controller ______________________________________________________________________________________________________________________________
    // gmx_btn_Navigation()
    // gmx_callNavi()
    // gmx_removeExeFunc()

    gmx_btn_Navigation(selection, style_, exec) {
      let defs = this.svg.append('defs')
      let grayGrandient = defs.append('linearGradient')
      .attr('id', `grayGrandient`).attr('x1', 0).attr('x2', 1).attr('y1', 0).attr('y2', 1)
      grayGrandient.append('stop').attr('stop-color', '#D2D8D9').attr('offset', '0').attr('stop-opacity', 1)
      grayGrandient.append('stop').attr('stop-color', '#FFFFFF').attr('offset', '1').attr('stop-opacity', 1)

      let home = 'M29.9,19.4l-5.2-5.2l-5.2-5l-5.2,5l-5.2,5.2h3.4v9.2h5.1v-7.2h3.8v7.2h5.1v-9.2 C26.5,19.4,29.9,19.4,29.9,19.4z'
      let back = 'M25.2,27.3c0.4,0.2,0.7,0,0.7-0.4V11.5c0-0.5-0.3-0.7-0.7-0.4l-13.3,7.8c-0.4,0.2-0.4,0.6,0,0.9 L25.2,27.3z'

      var btn_Home = selection
      .append('g')
      .attr('transform', `translate(${style_.x}, ${style_.y})scale(${style_.scale})`)
      .style('cursor', 'pointer')
      .on('mouseover', () => {
        btn_Home.transition().duration(150).style("opacity", 0.5)
      })
      .on('mouseout', () => {
        btn_Home.transition().duration(150).style("opacity", 1)
      })
      .call(this.gmx_callNavi, exec)

      btn_Home
      .append('path').attr('fill', '#B5BCBF')
      .attr('d', 'M2,29.5c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V11.2c0-5-4.1-9.1-9.1-9.1H11.1 c-5,0-9.1,4.1-9.1,9.1C2,11.2,2,29.5,2,29.5z')
      btn_Home
      .append('path').attr('fill', 'url(#grayGrandient)')
      .attr('d', 'M0.6,28c0,5,4.1,9.1,9.1,9.1h18.2c5,0,9.1-4.1,9.1-9.1V9.8c0-5-4.1-9.1-9.1-9.1H9.7  c-5,0-9.1,4.1-9.1,9.1C0.6,9.8,0.6,28,0.6,28z')
      btn_Home
      .append('path').attr('fill', '#5B6678')
      .attr('d', ()=> {
        if(style_.type == 'home') return home
        if(style_.type == 'back') return back
      })
    },

    gmx_callNavi(selection, exec) {
      let clickTimeout = false

      selection
      .on('mouseover', function() {
        d3.select(this).transition().duration(50).style('opacity', .7)
      })
      .on('mouseout', function() {
        d3.select(this).transition().duration(50).style('opacity', 1)
      })
      .on('click', () => {
        if(clickTimeout) return
        if (exec.remove && exec.remove.length > 0) this.gmx_removeExeFunc(exec)
          else exec.exeFunc(exec.data)
        clickTimeout = true
      }) 
    },

    gmx_removeExeFunc(exec) {
      let dur = 500, timeout = 1000

      if(exec.dur || exec.dur == 0) {
        dur = exec.dur
      }
      if(exec.timeout || exec.timeout == 0) timeout = exec.timeout
      
      exec.remove.forEach(d=> { 
        d.transition().duration(dur).style('opacity', 0) 
      })

      setTimeout(() => { 
        // remove selection
        exec.remove.forEach(d=> {
          if(d) d.remove()
        })

        exec.exeFunc(exec.data) // Call Function
      }, timeout)
    },


  }
}
