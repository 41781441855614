import icon from '../../../../../../includes/primitives/icons'
import * as d3 from 'd3'

export default {
  methods: {

    draw_ChartForm() {

      let canvas = this.canvas

      this.sCanvasArea
      .append('rect')
      .attr('x', 0).attr('y', 0)
      .attr('width', canvas.width)
      .attr('height', canvas.height)
      .attr('stroke', canvas.sColor)
      .attr('stroke-width', canvas.sWidth)
      .attr('fill', 'transparent')

      this.sCanvasArea
      .append('text')
      .attr('transform',`translate(20, ${canvas.main.y})`)
      .attr('font-size', canvas.main.tSize)
      .attr('fill', canvas.main.tColor)
      .attr('text-anchor', 'start')
      .text(canvas.main.name)

      this.sCanvasArea
      .append('text')
      .attr('transform',`translate(20, ${canvas.sub.y})`)
      .attr('font-size', canvas.sub.tSize)
      .attr('fill', canvas.sub.tColor)
      .attr('text-anchor', 'start')
      .text(canvas.sub.name)

      this.SummaryTable(this.sCanvasArea, 95, 170)

      this.gmx_WeekPerformance(this.sCanvasArea, {
        x: 400,
        y: 178,
        weekGap: 3,
        width: [35, 25],
        tColor: '#44A9DF',
        sColor: '#44A9DF',
        data: this.weekData
      })

      if(this.axis.grid.visible == 'Y') this.draw_Grid()
    },




    SummaryTable(selection, x_, y_) {

      let currData = this.chartData.find(f=> f.CDATE == this.cutOff)

      let summaryTable = selection
      .append('g')
      .attr('id', `Summary_Group`)
      .attr('transform', `translate(${x_}, ${y_})`)
      .attr('font-family', 'roboto')

      summaryTable
      .append('rect')
      .attr('transform',`translate(-8, -17)`)
      .attr('width', 75).attr('height', 22).attr('rx', 2).attr('ry', 2)
      .attr('fill', '#83D2F5')

      summaryTable
      .append('text')
      .attr('transform',`translate(0, -2)`).attr('font-size', 12).attr('fill', '#000').attr('text-anchor', 'start')
      .text('Grassroots')

      // Plan
      summaryTable
      .append('text')
      .attr('transform',`translate(0, 20)`).attr('font-size', 9).attr('fill', '#bcbcbc').attr('text-anchor', 'start')
      .text('Plan Cuml.')

      summaryTable
      .append('text')
      .attr('transform',`translate(50, 20)`).attr('font-size', 12).attr('fill', '#333').attr('text-anchor', 'start')
      .text(currData.PLAN_CUM)

      // Actual
      summaryTable
      .append('text')
      .attr('transform',`translate(80, 20)`).attr('font-size', 9).attr('fill', '#bcbcbc').attr('text-anchor', 'start')
      .text('Actual Cuml.')

      summaryTable
      .append('text')
      .attr('transform',`translate(140, 20)`).attr('font-size', 12).attr('fill', '#333').attr('text-anchor', 'start')
      .text(currData.ACT_CUM)

      // Actual
      let delta = currData.ACT_CUM - currData.PLAN_CUM
      summaryTable
      .append('text')
      .attr('transform',`translate(185, 20)`)
      .attr('font-size', 12).attr('text-anchor', 'start')
      .attr('fill', delta > 0 ? '#44A9DF' : '#EC407A')
      .text(delta > 0 ? `+${delta}` : delta)

      summaryTable // Delta
      .append('path')
      .attr('transform', `translate(170.5, 7.5)`)
      .attr('fill', delta > 0 ? '#44A9DF' : '#EC407A')
      .attr('d',  delta > 0 ? icon.arrow.up : icon.arrow.down)

      // Barchart
      let TOT = this.rawData.filter(f=> f.SCOPE == 'Grassroots').length
      let ACT = this.rawData.filter(f=> f.CSTD_ACTUAL !== undefined).length
      let REM = TOT - ACT
      let PRO = (ACT/TOT) * 100

      //console.log(ACT,PRO)

      this.gmx_Single_BarChart(summaryTable, {
          x: 0,
          y: 30,
          width: 100,
          col: 20,
          tColor: ['#757575', '#fff'],
          bColor: '#83D2F5',
          value: [TOT, ACT, REM, PRO, 70]
      })
    },

    draw_Grid() {
      let padding = this.canvas.padding
      let axis = this.axis

      this.sCanvasArea
      .selectAll('line')
      .data(this.grid)
      .enter()
      .append('line')
      .attr('x1', padding.left)
      .attr('x2', padding.left + this.canvas.chart.width)
      .attr('y1', d => padding.top + d)
      .attr('y2', d => padding.top + d)
      .attr('stroke', axis.grid.sColor)
      .attr('stroke-width', axis.grid.sWidth)
      .attr('opacity', axis.grid.opacity)
    }
  }
}