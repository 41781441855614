import moment from 'moment'
import * as d3 from 'd3'

export default {
  methods: {

    reset_Charts(item, disc) {

      // Reset Primary Axis
      let maxPrimary = d3.max(this.Discipline.filter(f=> f.checked == true), c => c.max_Cumul)
      this.scale.Primary = d3.scaleLinear()
      .domain([0, maxPrimary])
      .range([this.canvas.chart.height/2, 0])

      let maxSecondary = d3.max(this.Discipline.filter(f=> f.checked == true), c => c.max_Wk)
      this.scale.Secondary = d3.scaleLinear()
      .domain([0, maxSecondary])
      .range([this.canvas.chart.height/2, 0])

      this.grid.forEach((d,i)=> {
        d3.selectAll(`.Axis_Primary_${i}`).text((maxPrimary) * (i*2)/10)
        d3.selectAll(`.Axis_Secondary_${i}`)
        .text(maxSecondary > 1000 ?  (maxSecondary * (i*2)/10) : (maxSecondary) * (i*2)/10)
      })

      
      // Reset BarChart -------------------------------------------------------------
      let data = this.rawData.filter(f=> f.ITEM == item && f.DISC == disc)

      data.forEach((d,i)=> {
        // BAR Chart - CREATED
        d3.select(`#BAR_CREATED_${i}`).transition().duration(500)
        .attr('y', this.scale.Secondary(d.CREATED_WK))
        .attr('height', this.canvas.chart.height/2 - this.scale.Secondary(d.CREATED_WK))

        d3.select(`#VALUE_CREATED_${i}`).transition().duration(500)
        .attr('y', this.scale.Secondary(d.CREATED_WK) - 2)
        .attr('opacity', d.CREATED_WK == 0 ? 0.3 : 1)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d3.select(this).text(), d.CREATED_WK)
          return function(t) {
            d3.select(this).text(Math.round(interpolator(t)))
          }
        })

        // BAR Chart - CLOSED
        d3.select(`#BAR_CLOSED_${i}`).transition().duration(500)
        .attr('y', this.canvas.chart.height/2)
        .attr('height', this.canvas.chart.height/2 - this.scale.Secondary(d.CLOSED_WK))

        d3.select(`#VALUE_CLOSED_${i}`).transition().duration(500)
        .attr('y', this.canvas.chart.height/2 - this.scale.Secondary(d.CLOSED_WK) + this.canvas.chart.height/2 + 10)
        .attr('opacity', d.CLOSED_WK == 0 ? 0.3 : 1)
        .tween("text", function() {
          const interpolator = d3.interpolateNumber(d3.select(this).text(), d.CLOSED_WK)
          return function(t) {
            d3.select(this).text(Math.round(interpolator(t)))
          }
        })
      })
      
      // Line Chart -------------------------------------------------------------
      let lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-(this.barWidth / 2))
      .y(d => this.scale.Primary(d.REMAIN_CUM))
      d3.select(`#LINE_CHART_1`).transition().duration(500).attr('d', lineFunc(data))
      
      data.forEach((d,i)=> {
        d3.select(`#NODE_1_${i}`).transition().duration(500)
        .attr('cy', this.scale.Primary(d.REMAIN_CUM))
      })

      // Last Remain Value -------------------------------------------------------------
      let last = data.at(-1)
      d3.select(`#LAST_VALUE_1`).transition().duration(500)
      .attr('y', this.scale.Primary(last.REMAIN_CUM) + 3.5)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), last.REMAIN_CUM)
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })

      lineFunc = d3.line()
      .x(d => this.timeline.scale(moment(moment(d.CDATE).format('YYYY-MM-DD 23:59:59')).toDate())-(this.barWidth / 2))
      .y(d => this.scale.Primary(d.CONT_REMAIN))
      d3.select(`#LINE_CHART_2`).transition().duration(500).attr('d', lineFunc(data))
      
      data.forEach((d,i)=> {
        d3.select(`#NODE_2_${i}`).transition().duration(500)
        .attr('cy', this.scale.Primary(d.CONT_REMAIN))
      })

      d3.select(`#LAST_VALUE_2`).transition().duration(500)
      .attr('y', this.scale.Primary(last.CONT_REMAIN) + 3.5)
      .tween("text", function() {
        const interpolator = d3.interpolateNumber(d3.select(this).text(), last.CONT_REMAIN)
        return function(t) {
          d3.select(this).text(Math.round(interpolator(t)))
        }
      })


      // LEGENDS -------------------------------------------------------------
      let d = this.Discipline.find(f=> f.checked == true)
      d3.select(`#LEGEND_TXT`).text(`${d.ITEM.substr(0,1)} Punch`)
      d3.select(`#LEGEND_DISC`).text(disc == 'OV' ? 'Overall' : d.DISC_LONG)


    },
  }
}