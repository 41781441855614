import * as d3 from 'd3'

export default {
  methods: {
    link_Data(idx, col, d) {
      // set for link key value   ex) let dataSource = {KEY1:d.PKG,KEY2:d.DISC...}
      let dataSource = {} // DO not Remark if possible 

      // if linkCol is exist, set dataSource
      if(this.ColLink.length > 0){
        this.ColLink.forEach((d, i) => {
          let linkValue = this.Columns.find(f=> f.CODE == d).COL_LINK
          dataSource = {...dataSource, [linkValue]: this.rawData[idx][d]}
        })
      }

      // ********************************************************************************
      // console.log('dataSource: ', dataSource)
      // let filterTesting = col.FILTER !== '' ? this.filterCondition[col.FILTER] : ''
      // console.log('filterString_: ', filterTesting)
      // ********************************************************************************

      let requestText = `${col.JSON}`
      if (d.JSON) {
        requestText += `_${d.JSON}`
      }

      // set for request from A/G jsonProps
      let request_ = this.getRequestColumnProps(requestText, dataSource, this.ColumnProps)
      if(!request_) return


      // set for filterString 
      let filterString_ = (col.FILTER !== '') ? this.filterCondition[col.FILTER] : ''


      // if A/G filterString is exist, set request_.iFilters.filterString + filterString_
      if(request_.iFilters.filterString) {
        request_.iFilters.filterString += filterString_ ? ` AND ${filterString_} ` : ''
      } else {
        request_.iFilters.filterString = filterString_||''
      }


      // combine filterString and inputFilter &&& emit request-action
      request_ = {...request_,
        filters : {
          ...this.FilterValues,
          ...request_.filters,
        },
        iFilters: { 
          filterString : request_.iFilters.filterString||'',
          inputFilter : request_.iFilters.inputFilter,},
      }
      this.$emit('request-action', request_)
    },

  }
}