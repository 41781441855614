import * as d3 from 'd3'

export default {
  data: () => ({
    rawData:    [],

    SCurve:     null,

    timeline:   {},
    Chart:      {},
    Title:      { main: {},sub: {}, },
    Primary:    { maxValue: 0, title: {}, value: {}, },
    Legend:     {},
    Delta:      {},
    BarStyle:   [],

    scaleX:     null,
    scaleY:     null,
  }),

  computed: {
    ready2draw() {
      return (
        this.DataItems.length > 0 &&
        Object.keys(this.Canvas).length > 0
      )
    },
  },

  methods: {

    set_LocalValues(){
      // raw data
      this.rawData = JSON.parse(JSON.stringify(this.DataItems))

      // Styles
      let s_ = JSON.parse(JSON.stringify(this.Queries.SQL1[0]))

      this.timeline = {
        cutoff          : s_.TL_CUTOFF,
        tColor          : s_.TL_TEXT_TCOLOR,
        tSize           : s_.TL_TEXT_SIZE,
      }

      this.Chart.x                = s_.CHART_X
      this.Chart.y                = s_.CHART_Y
      this.Chart.sColor           = s_.CHART_BORDER_COLOR
      this.Chart.sWidth           = s_.CHART_BORDER_SWIDTH
      this.Chart.opacity          = s_.CHART_BORDER_OPACITY
      this.Chart.width            = s_.CHART_WIDTH
      this.Chart.height           = s_.CHART_HEIGHT
      this.Chart.barSize          = s_.CHART_BAR_SIZE

      this.Title.align            = s_.TITLE_ALIGN
      this.Title.main.visible     = s_.TITLE_MAIN_VISIBLE
      this.Title.main.Text        = s_.TITLE_MAIN
      this.Title.main.tColor      = s_.TITLE_MAIN_TCOLOR
      this.Title.main.tSize       = s_.TITLE_MAIN_TSIZE
      this.Title.main.margin      = s_.TITLE_MAIN_MARGIN
      
      this.Primary.title.name     = s_.PRIMARY_TITLE_NAME
      this.Primary.title.tColor   = s_.PRIMARY_TITLE_TCOLOR
      this.Primary.title.tSize    = s_.PRIMARY_TITLE_TSIZE
      this.Primary.title.margin   = s_.PRIMARY_TITLE_MARGIN
      this.Primary.value.tColor   = s_.PRIMARY_VALUE_TCOLOR
      this.Primary.value.tSize    = s_.PRIMARY_VALUE_TSIZE
      this.Primary.value.margin   = s_.PRIMARY_VALUE_MARGIN

      this.Legend.tColor          = s_.LEGEND_TCOLOR
      this.Legend.tSize           = s_.LEGEND_TSIZE
      this.Legend.margin          = s_.LEGEND_MARGIN
      this.Legend.align           = s_.LEGEND_ALIGN

      this.Delta.tSize           = s_.DELTA_TSIZE
      this.Delta.margin          = s_.DELTA_MARGIN
      this.Delta.up              = s_.DELTA_UP_COLOR
      this.Delta.down            = s_.DELTA_DOWN_COLOR

      // Line Chart Properties   
      let codes = s_.REF_CODE.replace(/\s/g, '').split('/')
      codes.forEach((d,i)=> {
        this.BarStyle.push({
          code      : s_.REF_CODE.replace(/\s/g, '').split('/')[i],
          name      : s_.NAME.replace(/\s/g, '').split('/')[i],
          type      : s_.TYPE.replace(/\s/g, '').split('/')[i],
          color     : s_.COLOR.replace(/\s/g, '').split('/')[i],
        })
      })

      // Get Max Value
      this.BarStyle.forEach((d,i)=> {
        d.max = Math.max(...this.rawData.map(m => m[`${d.code}`]))
        if (d.max > this.Primary.maxValue) this.Primary.max = d.max
      })
      this.Primary.max = this.getMaxBound_v2(this.Primary.max)

      this.scaleX = d3.scaleBand()
      .domain(this.rawData.map(d => d.CDATE ))
      .range([0, this.Chart.width]).padding(1)

      this.scaleY = d3.scaleLinear()
      .domain([0, this.Primary.max])
      .range([this.Chart.height, 0])
    },

  }
}